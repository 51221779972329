import React, { useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import LookBookPage from "./pages/LookBookPage";
import ProductPage from "./pages/ProductPage";
import AboutPage from "./pages/AboutPage";
import Footer from "./components/Footer";
import ShopPage from "./pages/Shop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TosPage from "./pages/TosPage";
import ContactPage from "./pages/ContactPage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthContext } from "./context/AuthContext";
import GoogleLoginbutton from "./components/GoogleLogin";
import ShippingPage from "./pages/Shipping";
import RefundPage from "./pages/Refund";
import OrdersPage from "./pages/OrdersPage";
import OrderPage from "./pages/OrderPage";
import ProfilePage from "./pages/ProfilePage";
import CartPage from "./pages/CartPage";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/signup" element={<Signup />} exact />
          <Route path="/login" element={<GoogleLoginbutton />} exact />
          <Route path="/shop" element={<ShopPage />} exact />
          <Route path="/shop/:id" element={<ProductPage />} exact />
          <Route path="/lookbook" element={<LookBookPage />} exact />
          <Route path="/about" element={<AboutPage />} exact />
          <Route path="/shipping" element={<ShippingPage />} exact />
          <Route path="/refund" element={<RefundPage />} exact />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} exact />
          <Route path="/terms" element={<TosPage />} exact />
          <Route path="/contactus" element={<ContactPage />} exact />
          <Route path="/profile" element={<ProfilePage />} exact />
          <Route path="/orders" element={<OrdersPage />} exact />
          <Route path="/order/:orderId" element={<OrderPage />} exact />
          <Route path="/cart" element={<CartPage />} exact />
        </Routes>
      </main>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Router>
  );
}

export default App;
