import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import StripeProvider from "./components/StripeProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <AuthProvider>
      <StripeProvider>
        <App />
      </StripeProvider>
    </AuthProvider>
  </GoogleOAuthProvider>,
);
