import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const checkAuthenticated = () => {
    const token = localStorage.getItem("authToken");
    const data = JSON.parse(localStorage.getItem("data"));

    if (!token) {
      setUser(null);
      return false;
    }

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("data");
        setUser(null);
        return false;
      }
      setUser(data);

      return true;
    } catch (error) {
      console.error("Error decoding token:", error);
      localStorage.removeItem("authToken");
      localStorage.removeItem("data");
      setUser(null);
      return false;
    }
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
