import React, { useContext, useEffect, useState } from "react";
import { ArrowDown, ArrowUp, BackButton, HeartIcon } from "../utils/Icons";
import { useNavigate, useParams } from "react-router-dom";
import useSingleProduct from "../custom-hooks/useSingleProduct";
import BottomSection from "../components/BottomSection";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { TitleComponent } from "../components/TitleComponent";
import { AuthContext } from "../context/AuthContext";
import { addItemToCart } from "../utils/cartUtils";
import notify from "../utils/toastUtil";

const ProductPage = () => {
  const id = useParams();
  const [product, isLoading] = useSingleProduct(id.id);
  const [showSizes, setShowSizes] = useState(true);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [price, setPrice] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showSizeForm, setShowSizeForm] = useState(false);
  const [showPriceForm, setShowPriceForm] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (product?.images) {
      setImageUrl(product?.images[0]?.fields?.file?.url);
    }
  }, [product]);

  const images = product?.images;
  const imageTitle = product?.images[0]?.fields?.title;
  const title = product?.title;
  const description = product?.description;
  const sizes = product?.sizes;
  const productDescriptions = product?.productdescription;

  useEffect(() => {
    if (showForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showForm]);

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (!size || !color || !price) {
      notify("Please select size, color and price", "error");
    } else {
      const data = {
        quantity: 1,
        product: product._id,
        productName: title,
        size: size,
        color: color,
        price: price,
      };
      addItemToCart(data);
      notify("Item added to cart successfully!", "success");
    }
  };

  return (
    <>
      <TitleComponent title={title} description={description} />
      <section className="flex w-full h-full p-6 bg-gray-100 px-2 mobile:flex mobile:flex-col">
        {/* Left Side: Product Image */}
        <div className="w-1/2 mobile:w-full flex items-start justify-center gap-2">
          <div className="flex flex-col gap-2 border cursor-pointer mobile:hidden  sm:hidden">
            {images &&
              images?.map((i, index) => (
                <img
                  src={i.fields.file.url}
                  key={index}
                  alt="images"
                  className={`${
                    imageUrl === i.fields.file.url && "border-4 border-black"
                  } w-32 h-32 object-cover object-top cursor-pointer`}
                  onClick={() => setImageUrl(i.fields.file.url)}
                  width={"100%"}
                  height={"100%"}
                />
              ))}
          </div>
          <img
            src={imageUrl}
            alt={imageTitle}
            className="w-full object-cover object-center mobile:hidden sm:hidden"
            width={"100%"}
            height={"100%"}
          />
          <div className="hidden sm:block mobile:block">
            <Carousel
              showArrows={false}
              showIndicators={false}
              showThumbs={false}
              width={"100%"}
              autoPlay={true}
              infiniteLoop={true}
            >
              {images &&
                images.map((i, index) => (
                  <div className="w-full h-[600px]" key={index}>
                    <img
                      src={i.fields.file.url}
                      alt={i.fields.title}
                      className="w-full object-cover object-center"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>

        {/* Right Side: Product Details */}
        <div className="w-1/2 mobile:w-full p-4">
          <nav className="text-sm mb-6 text-gray-600 font-CormorantGaramond font-normal flex items-center gap-2 ">
            <span className="cursor-pointer" onClick={() => navigate("/shop")}>
              <BackButton />
            </span>
            HOME / SHOP / ALL CLOTHING / {title}
          </nav>
          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-bold font-Qochy mb-5">{title}</h1>
            <div className="flex items-center gap-2 uppercase font-normal font-CormorantGaramond text-base cursor-pointer">
              Save{" "}
              <span>
                <HeartIcon />
              </span>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <span className="mr-2 font-CormorantGaramond text-sm font-bold">
              COLOUR
            </span>
            {product?.colors.map((c, i) => {
              const colorName = c.name.toLowerCase();
              return (
                <div
                  key={i}
                  className={`${colorName === color && "border-2 border-black"} w-6 h-6 rounded-full mr-2 cursor-pointer`}
                  style={{ backgroundColor: colorName }}
                  onClick={() => setColor(colorName)}
                ></div>
              );
            })}
          </div>
          <div className="">
            <button
              className="w-full py-4 bg-white font-CormorantGaramond text-sm font-bold rounded-sm text-center flex items-center justify-center"
              onClick={() => {
                setShowSizes(!showSizes);
                setShowPriceForm(false);
              }}
            >
              CHOOSE SIZE
              <span className="ml-2">
                {showSizes ? <ArrowUp /> : <ArrowDown />}
              </span>
            </button>
            {showSizes && (
              <div className="flex items-center gap-2 my-5">
                {sizes &&
                  sizes.map((s, i) => (
                    <div className="flex flex-col" key={i}>
                      <div
                        onClick={() => {
                          setSize(s.size);
                          setPrice(s.price);
                        }}
                        key={i}
                        className={`${
                          size === s.size && "bg-gray-lgray"
                        } w-12 h-12 cursor-pointer font-CormorantGaramond rounded-full border border-black flex items-center justify-center`}
                      >
                        {s.size}
                      </div>
                      <div>₹{s.price}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {/* form for request price */}
          {/* <div className="mb-4">
            {showPriceForm ? (
              <form className="w-full my-4">
                <div className="flex flex-col w-full gap-4">
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-base font-normal font-CormorantGaramond text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="you@company.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 font-normal font-CormorantGaramond rounded-md shadow-sm p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="phone"
                      className="block text-base font-normal font-CormorantGaramond text-gray-700"
                    >
                      Phone number
                    </label>
                    <div className="flex">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="+1 (555) 000-0000"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md font-normal font-CormorantGaramond shadow-sm p-2"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full py-2 text-gray-800 bg-gray-lgray font-bold font-CormorantGaramond rounded-md border"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <button
                className="w-full py-4 bg-gray-lgray font-CormorantGaramond text-sm font-bold text-center rounded-sm"
                onClick={() => {
                  setShowPriceForm(!showPriceForm);
                  setShowSizes(!showForm);
                }}
              >
                PRICE ON REQUEST
              </button>
            )}
          </div> */}
          <p className="mb-4 font-CormorantGaramond font-normal text-sm">
            {description}
          </p>
          <div className="my-8">
            <button
              className="py-4 w-44 border bg-white font-CormorantGaramond font-bold text-base"
              onClick={() => setShowSizeForm(!showSizeForm)}
            >
              WHAT’S MY SIZE ?
            </button>
          </div>
          {showSizeForm && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-hidden">
              <div className="bg-white rounded-lg shadow-lg w-[600px] mx-auto h-auto p-6 relative mobile:h-auto mobile:w-full sm:max-w-2xl overflow-auto">
                <button
                  onClick={() => setShowSizeForm(false)}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  &times;
                </button>
                <div className="flex items-center justify-between">
                  <div className="font-CormorantGaramond text-xl">
                    Size Chart{" "}
                  </div>
                  <div className="font-CormorantGaramond text-xl">
                    {" "}
                    In Inches
                  </div>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 font-CormorantGaramond text-xl">
                        Size
                      </th>
                      <th className="px-4 py-2 font-CormorantGaramond text-xl">
                        Brand Size
                      </th>
                      <th className="px-4 py-2 font-CormorantGaramond text-xl">
                        To fit bust (in inches)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizes.map((s, i) => (
                      <tr key={i} className="even:bg-gray-100 odd:bg-white">
                        <td className="border px-4 py-2 font-CormorantGaramond text-xl">
                          {s.size}
                        </td>
                        <td className="border px-4 py-2 font-CormorantGaramond text-xl">
                          {s.brandSize}
                        </td>
                        <td className="border px-4 py-2 font-CormorantGaramond text-xl">
                          {s.inches}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="flex mb-4 ">
            <button className="py-2 px-4 font-Qochy font-normal text-base border-b border-gray-300 uppercase">
              Product Description
            </button>
          </div>
          <ul className="list-disc pl-6 text-gray-600">
            {productDescriptions?.map((p, i) => (
              <li
                className="font-CormorantGaramond font-normal text-base"
                key={i}
              >
                {p.details}
              </li>
            ))}
          </ul>
          <div className="mt-4 font-CormorantGaramond font-normal text-sm">
            For more details of product book an appointment with us
          </div>
          <div className="mt-4">
            <button
              className="w-full py-4 uppercase bg-gray-lgray font-Qochy text-sm font-bold rounded-sm text-center flex items-center justify-center"
              onClick={() => setShowForm(!showForm)}
            >
              Book an Appointment
            </button>
            {showForm && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-hidden">
                <div className="bg-white rounded-lg shadow-lg max-w-5xl mx-auto h-auto p-6 relative mobile:h-full mobile:w-full sm:max-w-2xl sm:h-[600px] overflow-auto">
                  <button
                    onClick={() => setShowForm(false)}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                  <BottomSection />
                </div>
              </div>
            )}
          </div>
          <div className="">
            <button
              className="w-full py-4 bg-white font-Qochy text-sm font-bold rounded-sm text-center flex items-center justify-center"
              onClick={handleAddToCart}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductPage;
