import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ data, key }) => {
  const imageUrl = data?.fields?.images[0]?.fields?.file?.url;
  const imageTitle = data?.fields?.images[0]?.fields?.title;
  const title = data?.fields?.title;
  const description = data?.fields?.description;
  const sizes = data?.fields?.sizes;

  return (
    <Link to={`/shop/${data?.fields?.slug}`} key={key}>
      <div
        key={key}
        className="w-full h-auto overflow-hidden relative group border"
      >
        <img
          src={imageUrl}
          alt={`${1}`}
          className="w-full h-full object-cover border"
          width="100%"
          height="100%"
        />
        <div className="absolute bottom-0 left-0 bg-white w-full border px-5 py-2 transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-transform duration-500 ease-out">
          <p className="font-Qochy font-bold text-xl">{title}</p>
          <p className="font-CormorantGaramond font-normal text-base my-2">
            All sizes available & Partially customizable
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
