import React, { useContext, useEffect, useState } from "react";
import {
  clearCart,
  getCartItems,
  removeItemFromCart,
} from "../utils/cartUtils";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { apiPost } from "../utils/apiService";
import notify from "../utils/toastUtil";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    address: "",
    city: "",
    postalCode: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  // Load cart items from localStorage
  useEffect(() => {
    setCartItems(getCartItems());
  }, []);

  // Calculate total amount
  const totalAmount = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0,
  );

  const handleRemoveItem = (id) => {
    removeItemFromCart(id);
    setCartItems(getCartItems());
  };

  // Razorpay payment page
  const handlePayment = async () => {
    if (
      !shippingAddress.name ||
      !shippingAddress.address ||
      !shippingAddress.city ||
      !shippingAddress.postalCode ||
      !shippingAddress.phone
    ) {
      notify("Please fill in all the shipping details", "error");
      return;
    } else {
      setLoading(true);
      try {
        // Create order on the backend
        const amount = totalAmount * 100;
        const paymentData = {
          amount,
          products: cartItems,
          shippingAddress,
          totalPrice: amount,
          userId: user?.googleId,
        };
        const { data } = await apiPost(
          "/api/payment/create-payment-intent",
          paymentData,
        );

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: data.amount,
          currency: "INR",
          name: "Manekin",
          description: "Payment for Order",
          order_id: data.id,
          handler: function (response) {
            fetch(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                  products: cartItems,
                  shippingAddress,
                  totalPrice: totalAmount,
                  userId: user?.googleId,
                }),
              },
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.status === "ok") {
                  notify("Payment verified successfully", "success");
                  clearCart();
                  navigate("/order/" + data.order._id);
                } else {
                  notify("Payment verification failed", "error");
                }
              })
              .catch((error) => {
                notify("Error verifying payment", "error");
              });
          },
          prefill: {
            name: shippingAddress.name,
            email: user?.email || "example@example.com",
            contact: shippingAddress.phone,
          },
          theme: {
            color: "#F37254",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoading(false);
      } catch (error) {
        notify("Error creating payment order", "error");
      }
    }
  };

  // Handle input change for shipping details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress({ ...shippingAddress, [name]: value });
  };

  return (
    <div className="p-4 min-h-screen">
      <h1 className="text-2xl font-bold">Your Cart</h1>

      {cartItems.length === 0 ? (
        <p className="mt-4">Your cart is empty.</p>
      ) : (
        <div className="mt-4">
          {cartItems.map((item) => (
            <div
              key={item.id}
              className="flex justify-between items-center border-b py-2"
            >
              <div>
                <h2 className="text-lg">{item.productName}</h2>
                <p>Price: ₹{item.price}</p>
                <p>Size: {item.size}</p>
                <p>Quantity: {item.quantity}</p>
              </div>
              <button
                className="text-red-500"
                onClick={() => handleRemoveItem(item.size)}
              >
                Remove
              </button>
            </div>
          ))}

          <div className="mt-4">
            <h2 className="text-xl font-bold">Shipping Details</h2>
            <form className="mt-4 space-y-4">
              <input
                type="text"
                name="name"
                value={shippingAddress.name}
                onChange={handleInputChange}
                placeholder="Full Name"
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="text"
                name="address"
                value={shippingAddress.address}
                onChange={handleInputChange}
                placeholder="Address"
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="text"
                name="city"
                value={shippingAddress.city}
                onChange={handleInputChange}
                placeholder="City"
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="text"
                name="postalCode"
                value={shippingAddress.postalCode}
                onChange={handleInputChange}
                placeholder="Postal Code"
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="tel"
                name="phone"
                value={shippingAddress.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                className="w-full p-2 border rounded"
                required
              />
            </form>

            <h2 className="text-xl font-bold mt-4">Total: ₹{totalAmount}</h2>
            {cartItems.length > 0 && (
              <button
                className="w-full py-4 bg-gray-lgray font-Qochy text-sm font-bold rounded-lg text-center flex items-center justify-center"
                onClick={() => {
                  if (user) {
                    handlePayment();
                  } else {
                    notify("Please log in to proceed to payment.", "error");
                  }
                }}
              >
                {loading ? "Processing Payment..." : "Proceed to Payment"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
