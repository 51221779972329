import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeaders = () => {
  const token = localStorage.getItem("authToken");
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

// Generic GET request
export const apiGet = async (endpoint, params = {}) => {
  try {
    const response = await axios.get(`${API_URL}${endpoint}`, {
      headers: getAuthHeaders(),
      params,
    });
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
    throw error.response?.data || error.message;
  }
};

// Generic POST request
export const apiPost = async (endpoint, data) => {
  try {
    const response = await axios.post(`${API_URL}${endpoint}`, data, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.error("POST Request Error:", error);
    throw error.response?.data || error.message;
  }
};

// Generic PUT request
export const apiPut = async (endpoint, data) => {
  try {
    const response = await axios.put(`${API_URL}${endpoint}`, data, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("PUT Request Error:", error);
    throw error.response?.data || error.message;
  }
};

// Generic DELETE request
export const apiDelete = async (endpoint) => {
  try {
    const response = await axios.delete(`${API_URL}${endpoint}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("DELETE Request Error:", error);
    throw error.response?.data || error.message;
  }
};
