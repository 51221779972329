import React, { useState } from "react";
import img5 from "../assets/img5.png";
import { databases } from "../appwrite/config";
import { TitleComponent } from "../components/TitleComponent";
import notify from "../utils/toastUtil";

const ContactPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await databases.createDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_CONTACT_COLLECTION_ID,
        "unique()",
        {
          firstName,
          lastName,
          email,
          phone,
          message,
          privacyPolicy,
        },
      );
      notify(
        "Thanks for reaching out! We will get back to you shortly.",
        "success",
      );
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setPrivacyPolicy(false);
    } catch (error) {
      notify("Failed to submit data.", "error");
    }
  };

  return (
    <>
      <TitleComponent title="Contact Us" description="Manekin" />
      <section className="flex w-full h-full p-6 mobile:flex-col mt-10">
        {/* Left Side: Contact Form */}
        <div className="md:w-1/2 w-full p-6 bg-white">
          <h2 className="text-4xl font-bold mb-4 font-Qochy ">Get in touch</h2>
          <p className="mb-6 mt-4 text-gray-600 font-CormorantGaramond font-normal text-xl">
            Our friendly team would love to hear from you.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-base font-normal font-CormorantGaramond text-gray-700"
                >
                  First name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 font-normal font-CormorantGaramond rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-base font-normal font-CormorantGaramond text-gray-700"
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 font-normal font-CormorantGaramond rounded-md shadow-sm p-2"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-base font-normal font-CormorantGaramond text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="you@company.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full border border-gray-300 font-normal font-CormorantGaramond rounded-md shadow-sm p-2"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="phone"
                className="block text-base font-normal font-CormorantGaramond text-gray-700"
              >
                Phone number
              </label>
              <div className="flex">
                {/* <select
                name="countryCode"
                className="mt-1 block border border-gray-300 font-normal font-Glamour rounded-l-md shadow-sm p-2"
              >
                <option value="US">In</option>
                <option value="CA">CA</option>
                <option value="UK">UK</option>
              </select> */}
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="+1 (555) 000-0000"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md font-normal font-CormorantGaramond shadow-sm p-2"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-base font-normal font-CormorantGaramond text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="mt-1 block w-full border border-gray-300 font-normal font-CormorantGaramond rounded-md shadow-sm p-2"
                rows="4"
              ></textarea>
            </div>
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="privacyPolicy"
                name="privacyPolicy"
                value={privacyPolicy}
                onChange={(e) => setPrivacyPolicy(e.target.checked)}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label
                htmlFor="privacyPolicy"
                className="ml-2 block text-base text-gray-900 font-normal font-CormorantGaramond"
              >
                You agree to our friendly{" "}
                <a href="#" className="text-indigo-600 underline">
                  privacy policy
                </a>
              </label>
            </div>
            <div>
              <button
                type="submit"
                className="w-full py-2 text-gray-800 bg-gray-lgray font-normal font-CormorantGaramond text-lg rounded-md border"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>

        {/* Right Side: Image */}
        <div className="md:w-1/2 w-full h-[700px] flex items-center justify-center">
          <img
            src={img5}
            alt="Contact Us"
            className="w-full h-full object-contain object-center"
          />
        </div>
      </section>
    </>
  );
};

export default ContactPage;
